import React from "react";

import * as BannerStyles from "../../styles/session/banner.module.css";

const Banner = () => (
  <>
    <div className={BannerStyles.bannerBackground}></div>
  </>
);

export default Banner;
